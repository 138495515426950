import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import "./styles/style.css";
import { Helmet } from "react-helmet";

function Home() {
  const history = useHistory();
  const useScript = (history) => {
    useEffect(() => {
      var TxtType = function (el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = "";
        this.tick();
        this.isDeleting = false;
      };

      TxtType.prototype.tick = function () {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

        var that = this;
        var delta = 200 - Math.random() * 100;

        if (this.isDeleting) {
          delta /= 2;
        }

        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
        } else if (this.isDeleting && this.txt === "") {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }

        setTimeout(function () {
          that.tick();
        }, delta);
      };

      window.onload = function () {
        var elements = document.getElementsByClassName("typewrite");
        for (var i = 0; i < elements.length; i++) {
          var toRotate = elements[i].getAttribute("data-type");
          var period = elements[i].getAttribute("data-period");
          if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
        document.body.appendChild(css);
      };
    }, [history]);
  };
  useScript(history);

  return (
    <>
      <Helmet>
        <title>Tomáš Kulhavý | Tvorba webových stránek</title>
        <meta
          name="description"
          content="Jmenuji se Tomáš Kulhavý a jsem Freelance
          Full - Stack Web Developer. Jsem nakloněný všem požadavkům, včetně těch nestandardních
          či velmi komplexních."
        />
        <meta
          property="og:title"
          content="Tomáš Kulhavý | Tvorba webových stránek"
        />
        <link rel="canonical" href="https://www.tomas-kulhavy.cz/"></link>
      </Helmet>
      <Fade>
        <div className="text-center">
          <div className="caption font-weight-bold">
            <h1 className="text-light display-2">TOMÁŠ KULHAVÝ</h1>
            <div className="typignIntro mt-1">
              <br></br>
              <div class="c1">
                <h2 class="type text-light text-home2 mt-3">WEB DESIGN</h2>
              </div>
              <br></br>
              <div class="c2">
                <h2 class="type2 text-light text-home2 mt-3">
                  TVORBA WEBOVÝCH STRÁNEK
                </h2>
              </div>
              <br></br>
              <div class="c3">
                <h2 class="type3 text-light text-home2 mt-3">
                  ESHOP, POČÍTAČE
                </h2>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}

export default Home;
