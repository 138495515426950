import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container } from "reactstrap";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Tomáš Kulhavý | Not Found</title>
      </Helmet>
      <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <Card className="p-3 mb-5 error404 col-sm-6">
          <CardBody>
            <CardTitle tag="h1" className="text-dark">
              404 | NOT FOUND
            </CardTitle>
            <Link to="/">
              <button className="mt-5 disable">Zpět na hlavní stránku</button>
            </Link>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default NotFound;
