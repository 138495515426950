import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faGithubSquare,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Row, Col, Form } from "reactstrap";
import "./styles/style.css";
import axios from "axios";
import logotk from "../assets/logo-tk-inv.svg";
import { Fade } from "react-awesome-reveal";
import Helmet from "react-helmet";
import { Badge, Card } from "proomkatest";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

const Contact = () => {
  const [badge, setBadge] = useState("");
  const [badgeError, setBadgeError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      number: "",
      text: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Email/sendEmail`, {
          FullName: values.name,
          Email: values.email,
          TelNumber: values.number,
          Text: values.text,
        })
        .then(() => {
          setBadge("-active");
        })
        .catch(() => {
          setBadgeError("-active");
        });
    },
  });

  return (
    <>
      <Helmet>
        <meta name="description" content="Neváhejte mě kontaktovat." />
        <title>Tomáš Kulhavý | Kontakt</title>
        <link
          rel="canonical"
          href="https://www.tomas-kulhavy.cz/kontakt"
        ></link>
        <meta property="og:title" content="Tomáš Kulhavý | Kontakt" />
      </Helmet>
      <Fade>
        <div className="appColor page contact-page">
          <div className="intro plainC container text-center">
            <div className="inner-content">
              <Row className="paddingToBottom">
                <Col lg>
                  <h1 className="text-white sluzby display-2">KONTAKT</h1>
                  <p>
                    <br></br>
                  </p>
                  <div
                    className={`error-badge-card${badgeError}`}
                    onClick={() => {
                      setBadgeError("");
                    }}
                  >
                    <Fade>
                      <Card color="#d05555" width="16rem" height="6rem">
                        <Badge
                          top="1rem"
                          right="1rem"
                          colorHover="#d055553e"
                          textColor="#d05555"
                          textColorHover="#d43b3b"
                        >
                          <ion-icon name="alert-outline"></ion-icon>
                        </Badge>
                        <p>Stala se chyba</p>
                      </Card>
                    </Fade>
                  </div>
                  <div
                    className={`badge-card${badge}`}
                    onClick={() => {
                      setBadge("");
                    }}
                  >
                    <Fade>
                      <Card color="#e2e8f0" width="16rem" height="6rem">
                        <Badge
                          top="1rem"
                          right="1rem"
                          colorHover="#d055553e"
                          textColor="#d05555"
                          textColorHover="#d43b3b"
                        >
                          <ion-icon name="checkmark-done-outline"></ion-icon>
                        </Badge>
                        <p>E-mail byl odeslán</p>
                      </Card>
                    </Fade>
                  </div>
                  <Row className="d-flex justify-content-center">
                    <Col lg="6">
                      <Form
                        inline
                        className="contact-form-flex"
                        onSubmit={formik.handleSubmit}
                      >
                        <Row>
                          <div className="contact-form pe-3">
                            <div className="form-floating mb-3">
                              <input
                                type="name"
                                name="name"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Jméno a příjmení"
                                onChange={formik.handleChange}
                                invalid={Boolean(formik.errors.name)}
                                valid={formik.touched.name}
                              />
                              <label for="floatingInput">
                                Jméno a příjmení *
                              </label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                name="number"
                                type="tel"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Telefonní číslo"
                                onChange={formik.handleChange}
                                invalid={Boolean(formik.errors.number)}
                                valid={formik.touched.number}
                              />
                              <label for="floatingInput">
                                Telefonní číslo *
                              </label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="floatingInput"
                                placeholder="E-mail"
                                onChange={formik.handleChange}
                                invalid={Boolean(formik.errors.email)}
                                valid={formik.touched.email}
                              />
                              <label for="floatingInput">E-mail *</label>
                            </div>
                            <div className="form-floating mb-3">
                              <textarea
                                className={`form-control`}
                                id="floatingInput"
                                placeholder="Vaše zpráva"
                                name="text"
                                onChange={formik.handleChange}
                                invalid={Boolean(formik.errors.text)}
                                valid={formik.touched.text}
                              />
                              <label for="floatingInput">Vaše zpráva *</label>
                            </div>
                            <p className="text-start text-muted mt-1">
                              * - Povinné
                            </p>
                            <div className="buttonIco text-start">
                              {!formik.values.email ||
                              !formik.values.number ||
                              !formik.values.text ||
                              !formik.values.name ? (
                                <button className="disable mb-3 mt-1" disabled>
                                  Odeslat
                                </button>
                              ) : (
                                <button
                                  className="active mb-3 mt-1"
                                  type="submit"
                                >
                                  Odeslat
                                </button>
                              )}
                            </div>
                          </div>
                        </Row>
                      </Form>
                    </Col>
                    <Col lg className="text-center">
                      <Row>
                        <img className="logo" src={logotk}></img>
                      </Row>
                      <Row>
                        <a
                          className="text-white"
                          href="mailto:tom.kulhavy@seznam.cz"
                        >
                          tom.kulhavy@seznam.cz
                        </a>
                      </Row>
                      <Row>
                        <a
                          className="text-white"
                          href="https://www.mojedatovaschranka.cz/sds/detail?dbid=79dhbau"
                          target="_blank"
                        >
                          datová schránka ID: <strong>79dhbau</strong>
                        </a>
                      </Row>
                      <Row>
                        <a
                          className="text-white"
                          href="https://rejstrik-firem.kurzy.cz/zivnostnik/aGyUqJufkaw="
                          target="_blank"
                        >
                          IČO: <strong>14357909</strong>
                        </a>
                      </Row>
                      <Row className="space">
                        <a
                          className="tel text-white"
                          href="tel:+420 720 046 104"
                        >
                          +420 720 046 104
                        </a>
                      </Row>
                      <Row className="mb-3">
                        <div className="flexbox-centering">
                          <a
                            href="https://www.facebook.com/tomas.kulhavy.52/"
                            target="_blank"
                            className="btn-floating btn-sm"
                          >
                            <FontAwesomeIcon
                              className="fbico"
                              size="2x"
                              icon={faFacebookSquare}
                            />
                          </a>
                          <a
                            href="https://www.instagram.com/tomaslklb/"
                            target="_blank"
                            className="btn-floating btn-sm"
                          >
                            <FontAwesomeIcon
                              className="igico"
                              size="2x"
                              icon={faInstagram}
                            />
                          </a>
                          <a
                            href="https://github.com/TomasKulhavy/"
                            target="_blank"
                            className="btn-floating btn-sm"
                          >
                            <FontAwesomeIcon
                              className="gitico"
                              size="2x"
                              icon={faGithubSquare}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/in/tomaskulhavy-web"
                            target="_blank"
                            className="btn-floating btn-sm"
                          >
                            <FontAwesomeIcon
                              className="linkedico"
                              size="2x"
                              icon={faLinkedinIn}
                            />
                          </a>
                        </div>
                      </Row>
                      <Row>
                        <a className="text-white">
                          {new Date().getFullYear()} © Tomáš Kulhavý
                        </a>
                      </Row>
                      <Row>
                        <a
                          href="https://www.firmy.cz/detail/13367946-tomas-kulhavy-jiretin-pod-bukovou.html#pridat-hodnoceni"
                          target="_blank"
                          rel="noopener"
                          className="mt-3"
                        >
                          <img
                            width="197px"
                            src="https://www.firmy.cz/img/widgets/logo-tmave-pozadi-text.svg"
                            alt="Tomáš Kulhavý na Firmy.cz"
                          />
                        </a>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Link className="anchor" to="/cookies">Používání cookies 🍪</Link>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Contact;
