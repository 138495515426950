import React, { useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  ApplicationContext,
  SET_PAGE,
} from "../../provider/ApplicationProvider";

const BottomMenu = () => {
  const [{ stranka }, dispatch] = useContext(ApplicationContext);

  const changeStranka = useCallback((no) => {
    dispatch({ type: SET_PAGE, payload: no });
  }, [dispatch]);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        return changeStranka(0);

      case "/sluzby":
        return changeStranka(1);

      case "/o-mne":
        return changeStranka(2);

      case "/kontakt":
        return changeStranka(3);

      default:
        return changeStranka(0);
    }
  }, [changeStranka]);

  useEffect(() => {
    const list = document.querySelectorAll(".list");
    list.forEach((item) => item.classList.remove("active"));
    list[stranka].classList.add("active");
  }, [stranka]);

  return (
    <div className="d-flex justify-content-center align-center">
      <div className="navigation">
        <ul>
          <li className="list active" onClick={() => changeStranka(0)}>
            <Link to="/">
              <span className="icon">
                <ion-icon name="home-outline"></ion-icon>
              </span>
              <span className="text">Úvod</span>
            </Link>
          </li>
          <li className="list">
            <Link to="/sluzby" onClick={() => changeStranka(1)}>
              <span className="icon">
                <ion-icon name="desktop-outline"></ion-icon>
              </span>
              <span className="text">Služby</span>
            </Link>
          </li>
          <li className="list">
            <Link to="/o-mne" onClick={() => changeStranka(2)}>
              <span className="icon">
                <ion-icon name="person-outline"></ion-icon>
              </span>
              <span className="text">O mně</span>
            </Link>
          </li>
          <li className="list">
            <Link to="/kontakt" onClick={() => changeStranka(3)}>
              <span className="icon">
                <ion-icon name="mail-outline"></ion-icon>
              </span>
              <span className="text">Kontakt</span>
            </Link>
          </li>
          <div className="indicator"></div>
        </ul>
      </div>
    </div>
  );
};

export default BottomMenu;
