import React, { useEffect } from "react";
import "./styles/style.css";
import { Fade } from "react-awesome-reveal";
import Helmet from "react-helmet";

const Cookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="Používání cookies" />
        <title>Tomáš Kulhavý | Cookies 🍪</title>
        <meta property="og:title" content="Tomáš Kulhavý | Cookies" />
        <link
          rel="canonical"
          href="https://www.tomas-kulhavy.cz/cookies"
        ></link>
      </Helmet>
      <Fade>
        <div className="text-light container">
          <div className="">
            <h3 className="text-center sluzby display-2">
              POUŽÍVÁNÍ COOKIES 🍪
            </h3>
            <div className="fs-6">
              <p className="fs-4">Co jsou to cookies?</p>
              <p>
                Jde o malé datové soubory, které jsou nezbytné pro některé
                funkce webových stránek, jako je třeba přihlašování. Díky
                cookies si stránky mohou také zapamatovat různé nastavení, jako
                je jazyk, font a další možnosti, které jste si pro zobrazení
                stránek sami zvolili. To je důvod, proč cookies umísťujeme na
                váš počítač. Totéž dělá většina velkých internetových stránek a
                poskytovatelů.
              </p>
              <h3>Na co využíváme cookies na tomto webu?</h3>
              <p>
                Cookies lze podle trvanlivosti rozdělit na dva základní druhy
              </p>
              <h3 className="mt-3">Na našem webu používáme tyto cookies:</h3>
              <ul>
                <li>
                  <strong>Technické (krátkodobé)</strong> – jsou nezbytné pro
                  zajištění elementární funkce stránek, tj. vložení produktů do
                  košíku, proces nákupu a zobrazení verze stránky vyžadující
                  javascript nebo bez něj.
                </li>
                <li>
                  <strong>Google Analytics (dlouhodobé)</strong> - abychom Vám
                  mohli stránky lépe přizpůsobit, využíváme pro měření
                  anonymních dat o chování uživatelů nástroj Google Analytics.
                </li>
              </ul>
              <p>
                Cookies nikdy nepoužíváme k tomu, abychom vás osobně
                identifikovali a nikdy do nich neumisťujeme citlivá nebo osobní
                data.
              </p>
              <h3 className="mt-3">Jak zakázat Google Analytics?</h3>
              <p>
                Chcete-li zablokovat odesílání anonymních statistik pomocí
                nástroje Google Analytics, můžete k tomu využít plugin
                poskytovaný společností Google. Tento plugin je třeba stáhnout a
                nainstalovat do Vašeho prohlížeče.
              </p>
            </div>
            <p><br></br></p><p><br></br></p><p><br></br></p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Cookies;
