import React, { createContext, useReducer, useContext } from "react";

export const SET_PAGE = "SET_PAGE";

const copyMultidimensionalArray = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

const userId = {
  stranka: 0,
};

export const ApplicationContext = createContext(userId);

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_PAGE: {
      let temp = copyMultidimensionalArray(state);
      temp.stranka = action.payload;
      return temp;
    }
    default:
      return state;
  }
};

export const ApplicationProvider = ({ children, ...rest }) => {
  const [state, dispatch] = useReducer(appReducer, userId);
  return (
    <>
      <ApplicationContext.Provider value={[state, dispatch]}>
        {children}
      </ApplicationContext.Provider>
    </>
  );
};

export const useAuthContext = () => useContext(ApplicationContext);