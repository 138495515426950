import React, { useContext, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import "./styles/style.css";
import "./styles/cards.scss";
import yuki from "../assets/yuki.svg";
import para from "../assets/LOGO-paraklub.svg";
import edelweiss from "../assets/edelweiss.svg";
import pernicky from "../assets/pernicky.svg";
import chata from "../assets/chata.svg";
import { ApplicationContext, SET_PAGE } from "../provider/ApplicationProvider";
import { Fade } from "react-awesome-reveal";
import Helmet from "react-helmet";
import { Card, CardMenu, Badge } from "proomkatest";

const Sluzby = () => {
  const [{ stranka }, dispatch] = useContext(ApplicationContext);

  function changeStranka(no) {
    dispatch({ type: SET_PAGE, payload: no });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Nabízím Vám služby: webové stránky, e-shop, web design a další."
        />
        <title>Tomáš Kulhavý | Webové stránky, web design, eshop</title>
        <meta
          property="og:title"
          content="Tomáš Kulhavý | Webové stránky, web design, eshop"
        />
        <link rel="canonical" href="https://www.tomas-kulhavy.cz/sluzby"></link>
      </Helmet>
      <Fade>
        <Container className="page">
          <div className="text-center text-light">
            <h1 className="sluzby display-2">TVORBA WEBOVÝCH STRÁNEK</h1>
            <Row className="d-flex justify-content-evenly">
              <Col lg className="mt-3 box" onClick={(e) => changeStranka(3)}>
                <Link to="/kontakt">
                  <Card
                    color="#e2e8f0"
                    width="auto"
                    height="18rem"
                    isScalable
                    className="web-menu"
                  >
                    <Badge
                      top="1rem"
                      right="1rem"
                      color="#222831"
                      textColorHover="crimson"
                    >
                      <ion-icon name="desktop-outline"></ion-icon>
                    </Badge>
                    <CardMenu>
                      <h2>WEB, ESHOP</h2>
                      <p>
                        V dnešní době se žádné úspěšné podnikání neobejde bez
                        kvalitní internetové stránky. Nabízím profesionální
                        webové stránky, které kladou důraz na web design
                        a&nbsp;kompatibilitu pro mobilní zařízení.
                      </p>
                    </CardMenu>
                  </Card>
                </Link>
              </Col>
              <Col lg className="mt-3" onClick={(e) => changeStranka(3)}>
                <Link to="/kontakt">
                  <Card color="#e2e8f0" width="auto" height="18rem" isScalable>
                    <Badge
                      top="1rem"
                      right="1rem"
                      color="#222831"
                      textColorHover="crimson"
                    >
                      <ion-icon name="pencil-outline"></ion-icon>
                    </Badge>
                    <CardMenu>
                      <h2>WEB DESIGN</h2>
                      <p>
                        Potřebujete navrhnout jedinečný design pro Vaše webové
                        stránky, či jakékoliv propagační materiály? Jste tu
                        správně.
                      </p>
                    </CardMenu>
                  </Card>
                </Link>
              </Col>
              <Col lg className="mt-3" onClick={(e) => changeStranka(3)}>
                <Link to="/kontakt">
                  <Card color="#e2e8f0" width="auto" height="18rem" isScalable>
                    <Badge
                      top="1rem"
                      right="1rem"
                      color="#222831"
                      textColorHover="crimson"
                    >
                      <ion-icon name="chatbubbles-outline"></ion-icon>
                    </Badge>
                    <CardMenu>
                      <h2>POČÍTAČ, WEB</h2>
                      <p>
                        Potřebujete poradit s&nbsp;tvorbou webu nebo Vám
                        nefunguje počítač? Rád Vám poradím, jak vše optimálně
                        vyřešit.
                      </p>
                    </CardMenu>
                  </Card>
                </Link>
              </Col>
            </Row>
          </div>
          <div className="contact-ref text-white text-center"></div>
          <div className="reference-section">
            <div className="mt-2 text-center text-white">
              <p className="reference-heading">Reference</p>
              <div class="slider">
                <div class="slide-track pt-2">
                  <div class="slide">
                    <a href="https://paraklublbc.cz/" target="_blank">
                      <img src={para} height="80" width="auto" alt="Paraklub Liberec" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronika-sulkova.eu/" target="_blank">
                      <img src={yuki} height="80" width="auto" alt="Veronika Sulková" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://hoteledelweiss.cz/" target="_blank">
                      <img src={edelweiss} height="80" width="auto" alt="Wellness Hotel Edelweiss" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://chalupaferdinandov.cz/" target="_blank">
                      <img src={chata} height="80" width="auto" alt="Chalupa Ferdinandov" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronikavavrincova.cz/" target="_blank">
                      <img src="https://veronikavavrincova.cz/static/media/logo.2f80c5cea068d39d6f7c16d3712bfa61.svg" height="80" width="auto" alt="Veronika Vavrincová" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://luxusnipernicky.cz/" target="_blank">
                      <img src={pernicky} height="80" width="auto" alt="" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://paraklublbc.cz/" target="_blank">
                      <img src={para} height="80" width="auto" alt="Paraklub Liberec" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronika-sulkova.eu/" target="_blank">
                      <img src={yuki} height="80" width="auto" alt="Veronika Sulková" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://hoteledelweiss.cz/" target="_blank">
                      <img src={edelweiss} height="80" width="auto" alt="Wellness Hotel Edelweiss" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://chalupaferdinandov.cz/" target="_blank">
                      <img src={chata} height="80" width="auto" alt="Chalupa Ferdinandov" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronikavavrincova.cz/" target="_blank">
                      <img src="https://veronikavavrincova.cz/static/media/logo.2f80c5cea068d39d6f7c16d3712bfa61.svg" height="80" width="auto" alt="Veronika Vavrincová" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://luxusnipernicky.cz/" target="_blank">
                      <img src={pernicky} height="80" width="auto" alt="" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://paraklublbc.cz/" target="_blank">
                      <img src={para} height="80" width="auto" alt="Paraklub Liberec" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronika-sulkova.eu/" target="_blank">
                      <img src={yuki} height="80" width="auto" alt="Veronika Sulková" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://hoteledelweiss.cz/" target="_blank">
                      <img src={edelweiss} height="80" width="auto" alt="Wellness Hotel Edelweiss" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://chalupaferdinandov.cz/" target="_blank">
                      <img src={chata} height="80" width="auto" alt="Chalupa Ferdinandov" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronikavavrincova.cz/" target="_blank">
                      <img src="https://veronikavavrincova.cz/static/media/logo.2f80c5cea068d39d6f7c16d3712bfa61.svg" height="80" width="auto" alt="Veronika Vavrincová" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://luxusnipernicky.cz/" target="_blank">
                      <img src={pernicky} height="80" width="auto" alt="" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://paraklublbc.cz/" target="_blank">
                      <img src={para} height="80" width="auto" alt="Paraklub Liberec" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronika-sulkova.eu/" target="_blank">
                      <img src={yuki} height="80" width="auto" alt="Veronika Sulková" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://hoteledelweiss.cz/" target="_blank">
                      <img src={edelweiss} height="80" width="auto" alt="Wellness Hotel Edelweiss" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://chalupaferdinandov.cz/" target="_blank">
                      <img src={chata} height="80" width="auto" alt="Chalupa Ferdinandov" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronikavavrincova.cz/" target="_blank">
                      <img src="https://veronikavavrincova.cz/static/media/logo.2f80c5cea068d39d6f7c16d3712bfa61.svg" height="80" width="auto" alt="Veronika Vavrincová" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://luxusnipernicky.cz/" target="_blank">
                      <img src={pernicky} height="80" width="auto" alt="" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://paraklublbc.cz/" target="_blank">
                      <img src={para} height="80" width="auto" alt="Paraklub Liberec" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronika-sulkova.eu/" target="_blank">
                      <img src={yuki} height="80" width="auto" alt="Veronika Sulková" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://hoteledelweiss.cz/" target="_blank">
                      <img src={edelweiss} height="80" width="auto" alt="Wellness Hotel Edelweiss" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://chalupaferdinandov.cz/" target="_blank">
                      <img src={chata} height="80" width="auto" alt="Chalupa Ferdinandov" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://veronikavavrincova.cz/" target="_blank">
                      <img src="https://veronikavavrincova.cz/static/media/logo.2f80c5cea068d39d6f7c16d3712bfa61.svg" height="80" width="auto" alt="Veronika Vavrincová" />
                    </a>
                  </div>
                  <div class="slide">
                    <a href="https://luxusnipernicky.cz/" target="_blank">
                      <img src={pernicky} height="80" width="auto" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="break"></div>
        </Container>
      </Fade>
    </>
  );
};

export default Sluzby;
