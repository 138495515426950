import React from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import Home from "./components/Home";
import Sluzby from "./components/Sluzby";
import Contact from "./components/Contact";
import About from "./components/About";
import { ApplicationProvider } from "./provider/ApplicationProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import BottomMenu from "./components/layout/BottomMenu";
import Cookies from "./components/Cookies";
import NotFound from "./components/NotFound";

function App() {
  const history = useHistory();
  return (
    <div className="App">
      <ApplicationProvider>
        <BrowserRouter history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sluzby" component={Sluzby} />
            <Route exact path="/kontakt" component={Contact} />
            <Route exact path="/o-mne" component={About} />
            <Route exact path="/cookies" component={Cookies} />
            <Route path="*" component={NotFound} />
          </Switch>
          <BottomMenu />
        </BrowserRouter>
      </ApplicationProvider>
    </div>
  );
}

export default App;