import React, { useEffect } from "react";
import "./styles/style.css";
import { Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Jmenuji se Tomáš Kulhavý a jsem Freelance
          Full - Stack Web Developer. Jsem nakloněný všem požadavkům, včetně těch nestandardních
          či velmi komplexních."
        />
        <title>Tomáš Kulhavý | O mně</title>
        <meta property="og:title" content="Tomáš Kulhavý | O mně" />
        <link rel="canonical" href="https://www.tomas-kulhavy.cz/o-mne"></link>
      </Helmet>
      <Fade>
        <div className="text-light container page">
          <div className="">
            <h3 className="text-center sluzby display-2">O MNĚ</h3>
            <div className="d-flex justify-content-center align-middle">
              <Row>
                <Col lg className="text-center card-container">
                  <div className="image-card perspective-left" />
                </Col>
                <Col lg="6" className="about-text paddingToBottom">
                  <p className="fs-4">Vítejte na mé stránce,</p>
                  <div className="fs-6">
                    <p>
                      chcete webové stránky podle vašich představ
                      v&nbsp;nadstandardní kvalitě a&nbsp;přesto za rozumnou
                      cenu s&nbsp;vlastním administračním rozhraním pro správu
                      webu na míru nebo potřebujete poradit s&nbsp;Vaším
                      počítačem?
                    </p>
                    <p>
                      Jsem nakloněný všem požadavkům, včetně těch nestandardních
                      či velmi komplexních.
                    </p>
                    <p>
                      Jmenuji se Tomáš Kulhavý a&nbsp;jsem Freelance
                      Full&nbsp;-&nbsp;Stack Web Developer.
                    </p>
                    <p>
                      Vystudoval jsem Střední průmyslovou školu strojní
                      a&nbsp;elektrotechnickou v&nbsp;Liberci. Věnuji se tvorbě
                      webových stránek a&nbsp;e-shopů.
                    </p>
                    <p>
                      <Link className="anchor" to="/sluzby">
                        REFERENCE ←
                      </Link>
                    </p>
                    <p>
                      Znalosti:
                      <ul className="mt-1 sluzby-list">
                        <li className="react">React.js (JavaScript)</li>
                        <li className="csharp">C#</li>
                        <li className="java">Java</li>
                        <li className="html">HTML</li>
                        <li className="css">CSS</li>
                        <li className="sql">SQL</li>
                        <li className="psd">Adobe Photoshop</li>
                        <li className="ai">Adobe Illustrator</li>
                        <li className="xd">Adobe Xd</li>
                        <li className="cisco">Cisco Networks</li>
                      </ul>
                    </p>
                    <p></p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default About;
